<script setup lang="ts">
defineProps({
  pageData: {
    type: Object,
    default: () => {}
  }
})

const breadcrumbsLinks = computed(() => ({
  links: [
    {
      label: "Home",
      to: "/"
    }
  ],
  currentPage: "Sconti"
}))

const { discountCodes, handleApplyDiscountCode } = useDiscountCodes()
</script>

<template>
  <div class="wrapper_discount-code grid-standard">
    <UtilsBreadcrumbs
      v-bind="breadcrumbsLinks"
      class="negative-margined col-span-full"
    />

    <BannerDiscountPromoHeroBanner
      class="negative-margined col-span-full mb-3 md:mb-11"
    />

    <BannerDiscountPromoOrganismDiscountCard
      v-if="discountCodes"
      :discount-cards="discountCodes"
      @on-click-c-t-a="($event) => handleApplyDiscountCode($event)"
      class="negative-margined col-span-full"
    />

    <DynamicContent
      v-if="pageData.content"
      class="cms-content negative-margined col-span-full"
      :components="pageData.content"
    />
  </div>
</template>

<style lang="scss" scoped>

.cms-content {
  :deep(.cms-questions) {
    @apply padded py-10;
  }
}

</style>
