<script setup lang="ts">
import { OrganismDiscountCardProps } from "./OrganismDiscountCard.props"

const props = defineProps<OrganismDiscountCardProps>()

defineEmits<{
  (e: "onClickCTA", index: number): void
}>()

const hasValidDiscountCodes = computed(() =>
  props.discountCards?.some((card) => card.status !== "used")
)
</script>

<template>
  <div
    class="discount-card-organism-container padded bg-grey-main py-6 md:pb-10"
  >
    <h1 class="hyppo-bold md:mammoth-bold mb-4 text-green-main">
      {{ $t("banner.discountPromo.organismDiscountCard.title") }}
    </h1>

    <div class="mb-6 flex items-center gap-2 md:mb-10">
      <UtilsIcon name="Info.svg" class="h-6 w-6 flex-shrink-0" />

      <p class="beaver-medium md:pig-medium">
        {{ $t("banner.discountPromo.organismDiscountCard.text") }}
      </p>
    </div>

    <div
      v-if="!hasValidDiscountCodes"
      class="mb-14 flex flex-col items-center gap-4 md:mb-16"
    >
      <UtilsIcon name="DiscountCodeNotFound.svg" />

      <p class="dolphin-bold">
        {{ $t("banner.discountPromo.organismDiscountCard.discountNotFound") }}
      </p>

      <utilsMarkdown
        class="mouse text-center"
        :content="
          $t('banner.discountPromo.organismDiscountCard.discountAlreadyUsed')
        "
      />
    </div>

    <div v-if="discountCards" class="flex flex-col gap-6">
      <div
        class="rounded-lg bg-white py-6 md:p-6"
        v-for="(card, index) in discountCards"
        :key="index"
      >
        <CardDiscountCode
          v-bind="card"
          @onClickCTA="$emit('onClickCTA', index)"
          :is-big="true"
        />
      </div>
    </div>
  </div>
</template>
