<script setup lang="ts">
const { t } = useI18n()

const iconsSrcMapping = {
  onlineDiscount1: { name: "OnlineDiscount1.svg", color: "strawberry-custom" },
  sale1: { name: "Sale1.svg", color: "strawberry-custom" },
  discount1: { name: "Discount1.svg", color: "strawberry-custom" }
}

interface DiscountInfo {
  icon: keyof typeof iconsSrcMapping
  text: string
}

const discountInfo: DiscountInfo[] = [
  {
    icon: "onlineDiscount1",
    text: t("banner.discountPromo.banner.info1")
  },
  {
    icon: "sale1",
    text: t("banner.discountPromo.banner.info2")
  },
  {
    icon: "discount1",
    text: t("banner.discountPromo.banner.info3")
  }
]
</script>

<template>
  <div
    class="
      discount-banner-wrapper
      padded
      flex flex-col
      items-center
      gap-6
      bg-gradient-to-br
      from-strawberry-main
      to-strawberry-200
      py-6
      text-white
      md:flex-row
      md:py-14
    "
  >
    <div class="flex-1 space-y-6 md:space-y-2">
      <p class="mammoth-bold md:whale-bold">
        {{ $t("banner.discountPromo.banner.title") }}
      </p>

      <p class="beaver-medium md:pig-medium">
        {{ $t("banner.discountPromo.banner.text") }}
      </p>
    </div>

    <div
      class="
        custom-info-container
        w-full
        flex-1
        rounded-3xl rounded-bl-none
        bg-white
      "
    >
      <div
        v-for="item in discountInfo"
        :key="item.icon"
        class="flex items-center gap-3 px-3 py-1 md:gap-6 md:px-4 md:py-2"
      >
        <UtilsIcon
          v-bind="iconsSrcMapping[item.icon]"
          fetch-priority="high"
          :preload="true"
          class="h-8 w-8 flex-shrink-0 md:h-12 md:w-12"
        />

        <p class="mouse-bold text-black-main md:text-xl">
          {{ item.text }}
        </p>
      </div>
    </div>
  </div>
</template>
