<script setup lang="ts">
const { locale } = useI18n()

const { data, error } = await usePage(
  `/api/gluon/fetchDiscountCodesPage/${locale.value}`
)

if (error.value) {
  throw createError({
    message: error.value?.message,
    statusCode: 404
  })
}
</script>

<template>
  <div class="page-discount-codes">
    <SeoBase v-if="data?.seo" v-bind="data?.seo" />
    <PagesDiscountCode v-if="data" :page-data="data" />
  </div>
</template>
